import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"

import SEO from "../components/seo"

import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader
import { Carousel } from "react-responsive-carousel"
import "./style.css"

import imghf from "../images/homme-femme.svg"
import imgrev from "../images/revenus.svg"
import imgbarometrespa from "../images/fragilite-pa.svg"
import imgbarometresante from "../images/sante.svg"
import imgFragiliteEnfant from "../images/fragilite-enfant.svg"
import imgFragiliteNumerique from "../images/fragilite-numerique.svg"
import imgFragiliteEconomique from "../images/fragilite-economique2.svg"

import imgSonar from "../images/Sonar_logo_V4-250x131.png"
import imgCompasZoom from "../images/Logo-CompaszOOm.png"

import imgHabitants from "../images/bulle_monde.svg"
import imgExperience from "../images/entreaide.svg"
import imgProximite from "../images/entreaide2.svg"
import imgIDT from "../images/idt.svg"

import imgObsInegalites from "../images/logo-obs.png"
import imgGazette from "../images/logo-gazette.png"
import imgUnccas from "../images/logo-unccas.png"
import logoCOS from "../images/logo-COS.jpg"

import imgLogo from "../images/logo.png"
import {
  Flex,
  Text,
  Stack,
  Box,
  Button,
  Link as ChakraLink,
  Icon,
  Image,
  SimpleGrid,
} from "@chakra-ui/core"

// https://www.gatsbyjs.org/docs/path-prefix/

const IndexPage = () => (
  <Layout landingPage>
    <SEO title="Accueil" />
    <Box my={15}>
      <Text fontSize="24px" fontWeight="600" ml="5">
        Où en êtes-vous sur votre territoire ?
      </Text>
      <Carousel
        showArrows={false}
        showStatus={false}
        showIndicators={true}
        autoPlay={true}
        showThumbs={true}
        thumbWidth={75}
        stopOnHover={true}
        infiniteLoop={true}
        interval={8000}
      >
        <div>
          <img
            src={imgFragiliteEconomique}
            alt="Les impacts de la crise économique"
          />
          <Box className="legend">
            <Stack>
              <Text fontSize="xl" fontWeight="800">
                Baromètre de la fragilité économique
              </Text>
              <Text fontSize="xs">
                La crise sanitaire a déjà évolué vers une crise économique, à la
                fois pour les ménages et pour les entreprises.
                <br />
                Ce baromètre est conçu pour anticiper les besoins émergents.
              </Text>
              <Button
                variantColor="blue"
                as={Link}
                to="fragilite-economique"
                py={8}
              >
                Découvrez le baromètre
                <br /> pour votre département
              </Button>
            </Stack>
          </Box>
        </div>

        <div>
          <img src={imgbarometresante} alt="Les besoins potentiels en santé" />
          <Box className="legend">
            <Stack>
              <Text fontSize="xl" fontWeight="800">
                Les besoins potentiels en santé
              </Text>
              <Text fontSize="xs">
                La crise sanitaire que nous vivons actuellement révèle, sur
                l’ensemble du territoire national, des besoins en matière de
                santé pour l’ensemble de la population. Le Compas a développé un
                baromètre déclinant les déterminants sociaux de santé.
              </Text>
              <Button variantColor="blue" as={Link} to="sante" py={8}>
                Découvrez le baromètre
                <br /> pour votre département
              </Button>
            </Stack>
          </Box>
        </div>

        <div>
          <img src={imgbarometrespa} alt="La fragilité des Personnes âgées" />

          <Box className="legend">
            <Stack>
              <Text fontSize="xl" fontWeight="800">
                La fragilité des personnes âgées
              </Text>
              <Text fontSize="xs">
                La crise sanitaire a mis en avant les fragilités des seniors
                face à la problématique de santé. Le Compas a développé un
                baromètre©Compas permettant de quantifier et de qualifier les
                besoins sous jacents.
              </Text>
              <Button
                variantColor="blue"
                as={Link}
                py={8}
                to="fragilite-des-personnes-agees"
              >
                Quelle est la situation
                <br /> de votre département ?
              </Button>
            </Stack>
          </Box>
        </div>

        <div>
          <img src={imgFragiliteEnfant} alt="La fragilité des enfants" />
          <Box className="legend">
            <Stack>
              <Text fontSize="xl" fontWeight="800">
                La fragilité des enfants
              </Text>
              <Text fontSize="xs">
                Depuis 2016, nous alertons sur les questions de{" "}
                <Text as="em">fragilité des enfants</Text> et pour cela nous
                avons créé un baromètre©Compas.
                <br />
                Construit en collaboration avec des professionnels de terrain,
                il peut être utilisé pour toutes les tranches d’âge, de la toute
                petite enfance à la fin de l’adolescence.
              </Text>
              <Button
                variantColor="blue"
                as={Link}
                to="fragilite-des-enfants"
                py={8}
              >
                Analysez la situation
                <br /> dans votre département
              </Button>
            </Stack>
          </Box>
        </div>

        <div>
          <img src={imgFragiliteNumerique} alt="La fragilité numérique" />

          <Box className="legend">
            <Stack>
              <Text fontSize="xl" fontWeight="800">
                L’accès aux droits et le numérique
              </Text>
              <Text fontSize="xs">
                Le confinement a mis en évidence l’existence d'une part
                importante de la population en rupture face au numérique.
                <br />
                Quelles sont ces populations qui ont le moins de ressources pour
                accéder de manière autonome à l’e-administration ?
              </Text>
              <Button
                variantColor="blue"
                as={Link}
                py={8}
                to="fragilite-acces-numerique"
              >
                Découvrez le premier éclairage
                <br /> sur votre département
              </Button>
            </Stack>
          </Box>
        </div>
        <div>
          <img src={imgIDT} alt="Les dynamiques des territoires" />
          <Box className="legend">
            <Stack>
              <Text fontSize="xl" fontWeight="800">
                L'indice de développement des territoires
              </Text>
              <Text fontSize="xs">
                Mesurer et comprendre les écarts dans le développement des
                territoires, cet indice a été développé pour mieux cibler les
                interventions des politiques territoriales.
              </Text>
              <Button
                variantColor="blue"
                as={Link}
                to="dynamiques-des-territoires"
                py={8}
              >
                Appréhendez la situation
                <br /> pour votre département
              </Button>
            </Stack>
          </Box>
        </div>
        <div>
          <img src={imgrev} alt="revenus niveaux de vie" />

          <Box className="legend">
            <Stack>
              <Text fontSize="xl" fontWeight="800">
                Niveaux de vie
              </Text>
              <Text fontSize="xs">
                Avec combien vivent les plus pauvres et les plus riches de ma
                commune et de mon département ? Gagne-t-on plus ou moins que la
                moyenne de la France ? Ce comparateur permet à chaque citoyen de
                faire le point sur les revenus dans sa ville.
              </Text>
              <Button variantColor="blue" as={Link} py={8} to="niveaux-vie">
                Observez la situation
                <br /> sur votre territoire
              </Button>
            </Stack>
          </Box>
        </div>

        {/* <div>
          <img src={imghf} alt="Inégalités femmes-hommes" />
          <Box className="legend">
            <Stack>
              <Text fontSize="xl" fontWeight="800">
                Inégalités femmes-hommes
              </Text>
              <Text fontSize="xs">
                Combien gagnent les femmes de votre commune par rapport aux
                hommes ? Quel est leur niveau de formation, leur taux d’activité
                ou leur place parmi les cadres supérieurs? Ce comparateur donne
                un éclairage sur l’égalité entre femmes et hommes .
              </Text>
              <Button
                variantColor="blue"
                as={ChakraLink}
                py={8}
                href="http://www.comparateur-territoires.fr/femmes-hommes/"
              >
                Visualisez ces différences
                <br /> sur votre commune
              </Button>
            </Stack>
          </Box>
        </div> */}
      </Carousel>
    </Box>
    <Flex
      alignItems="center"
      backgroundColor="#EFEFE6"
      minHeight="200px"
      p={{ sm: "5", md: "20", lg: "60" }}
      fontSize={{ sm: "1em", lg: "1.2em" }}
    >
      <ChakraLink isExternal href="http://www.lecompas.fr" flexBasis="20%">
        <Image src={imgLogo} alt="logo compas" />
      </ChakraLink>

      <Stack spacing={5} px={10} flexBasis="80%">
        <Text>
          Au{" "}
          <ChakraLink href="http://www.le-compas.fr" isExternal>
            Compas <Icon name="external-link" mx="2px" />
          </ChakraLink>
          ,{" "}
          <Text as="strong">
            nous cherchons à objectiver les besoins sociaux{" "}
          </Text>{" "}
          en vous proposant des données fines et en mettant toujours en débat
          l'information disponible.
        </Text>
        <Text>
          Constitué d'indicateurs uniques, ce comparateur apporte un{" "}
          <Text as="strong">éclairage de la situation de votre territoire</Text>
          . Ce premier niveau d'information peut révéler des écarts et des
          situations différenciées entre votre territoire et l'ensemble du
          territoire français.
        </Text>
      </Stack>
    </Flex>

    <Flex
      alignItems="center"
      backgroundColor="#d8d8e4"
      minHeight="200px"
      p={{ base: 10, sm: 0, md: 0, lg: 4 }}
      fontSize={{ sm: "1em", lg: "1.2em" }}
      justifyContent="center"
      flexDirection="column"
      mt={1}
    >
      <Box p={5}>
        <Text as="strong">Spécialiste du développement social local</Text>, le
        Compas s'est construit depuis plus de 20 ans grâce à ses valeurs
        humaines, son réseau, ses partenaires, ses outils et méthodes
        continuellement renouvelés et son savoir-faire reconnu.
      </Box>
      <Flex flexDirection="row" alignItems="flex-start" justifyContent="center">
        <Flex flexBasis={"20%"} p={5}>
          <Flex flexDirection="column" alignItems="center" textAlign="center">
            {/* <Text as="strong">Engagements</Text> */}
            <Image src={imgHabitants} />
            <Text>
              Améliorer le mieux-vivre des habitants et mettre en évidence les
              besoins invisibles.
            </Text>
          </Flex>
        </Flex>
        <Flex flexBasis={"20%"} p={5}>
          <Flex flexDirection="column" alignItems="center" textAlign="center">
            {/* <Text as="strong">Expérience</Text> */}
            <Image src={imgExperience} />
            <Text>
              Capitaliser les réalités sociales pour identifier les nouveaux
              enjeux.
            </Text>
          </Flex>
        </Flex>
        <Flex flexBasis={"20%"} p={5}>
          <Flex flexDirection="column" alignItems="center" textAlign="center">
            {/* <Text as="strong">Proximité</Text> */}
            <Image src={imgProximite} />
            <Text>
              Tisser une relation de confiance pour construire ensemble les
              politiques de demain.
            </Text>
          </Flex>
        </Flex>
        <Flex flexBasis={"20%"} p={5}>
          <Flex flexDirection="column" alignItems="center" textAlign="center">
            <Text as="strong">Mieux nous connaître</Text>
            <Image src={imgLogo} my={5} />
            <ChakraLink
              href="http://www.lecompas.fr"
              isExternal
              // ml="10"
              backgroundColor="rgb(22,171,228)"
              color="white"
              p="1"
              my={5}
            >
              lecompas.fr
              <Icon name="external-link" mx="2px" />
            </ChakraLink>
          </Flex>
        </Flex>
      </Flex>
    </Flex>

    <Flex
      alignItems="center"
      //  backgroundColor="#DDCECF"
      minHeight="200px"
      p={{ base: 10, sm: 0, md: 0, lg: 4 }}
      fontSize={{ sm: "1em", lg: "1.2em" }}
      justifyContent="center"
      flexDirection="column"
      mt={1}
    >
      <Box p={5}>
        <Text as="strong">Nous partageons nos réflexions avec vous</Text>
      </Box>
      <Flex
        flexDirection="column"
        // alignItems="flex-start"
        justifyContent="flex-start"
        alignItems="center"
        p={10}
        fontSize="md"
      >
        <Flex flexDirection="row" alignItems="center" textAlign="left">
          <Box flexBasis="30%" p={10}>
            <ChakraLink href="http://www.observationsociete.fr/" isExternal>
              <Image src={logoCOS} />
            </ChakraLink>
          </Box>
          <Box flexBasis="70%">
            <Text>
              Depuis sa création en 2011 par le Compas, le{" "}
              <ChakraLink href="http://www.observationsociete.fr/" isExternal>
                Centre d'Observation de la société{" "}
                <Icon name="external-link" mx="2px" />
              </ChakraLink>{" "}
              alimente un état des lieux, dégage des tendances de fond, et donne
              au citoyen un ensemble d’outils pour mieux comprendre la société.
            </Text>
          </Box>
        </Flex>

        <Flex flexDirection="row" alignItems="center" textAlign="left">
          <Box flexBasis="30%" p={10}>
            <ChakraLink
              href="http://www.lecompas.fr/ressource/nos-publications/"
              isExternal
            >
              <Image src={imgCompasZoom} />
            </ChakraLink>
          </Box>
          <Box flexBasis="70%">
            <Text>
              <ChakraLink
                href="http://www.lecompas.fr/ressource/nos-publications/"
                isExternal
              >
                Compas zOOm <Icon name="external-link" mx="2px" />
              </ChakraLink>{" "}
              est une note d’informations au service de l’action. Elle présente
              de façon synthétique des résultats de l’expertise et de
              l’expérience acquises au sein de notre bureau d’études. Avec cette
              publication, le Compas souhaite proposer un espace d’expressions
              de nouvelles pistes de réflexion et d’action autour des enjeux de
              cohésion sociale.
            </Text>
          </Box>
        </Flex>

        <Flex flexDirection="row" alignItems="center" textAlign="left">
          <Box flexBasis="30%" p={10}>
            <ChakraLink
              href="http://www.lecompas.fr/base-documentaire/"
              isExternal
            >
              <Text as="strong" fontSize="24px">
                Base documentaire
              </Text>
            </ChakraLink>
          </Box>
          <Box flexBasis="70%">
            <Text>
              Depuis 2005, nous mettons à disposition à tous les internautes une{" "}
              <ChakraLink
                href="http://www.lecompas.fr/base-documentaire/"
                isExternal
              >
                base documentaire <Icon name="external-link" mx="2px" />
              </ChakraLink>{" "}
              rassemblant les études et références publiques sur les thématiques
              de l'action sociale, les conditions de vie, la famille, les
              revenus, la santé et le travail entre autre.
            </Text>
          </Box>
        </Flex>
      </Flex>
    </Flex>

    <Flex
      backgroundColor="#DDCECF"
      minHeight="200px"
      p={{ sm: 10, md: 20, lg: 20 }}
      fontSize={{ sm: "1em", lg: "1.2em" }}
      alignItems="flex-start"
      justifyContent="center"
      flexDirection="column"
      width="100%"
      my={1}
    >
      <Box>
        <Text as="strong">Nos partenaires</Text>
        <Text>
          Pour ce comparateur de territoires, nous restons associés avec nos
          partenaires
        </Text>
      </Box>

      <Flex
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
      >
        <ChakraLink
          href="http://www.inegalites.fr/"
          isExternal
          my={{ base: 0, md: 4 }}
          mr={{ base: 2, md: 10 }}
        >
          <Image
            src={imgObsInegalites}
            alt="logo observatoire des inégalités"
            htmlWidth="150px"
          />
        </ChakraLink>
        <ChakraLink
          href="https://www.unccas.org/"
          isExternal
          m={{ base: 2, md: 10 }}
        >
          <Image src={imgUnccas} alt="logo unccas" htmlWidth="150px" />
        </ChakraLink>
        <ChakraLink
          href="http://www.lagazettedescommunes.com/"
          isExternal
          m={{ base: 2, md: 10 }}
        >
          <Image
            src={imgGazette}
            alt="logo gazette des communes"
            htmlWidth="150px"
          />
        </ChakraLink>
      </Flex>
    </Flex>
  </Layout>
)

export default IndexPage
